import React, { useState, useEffect } from "react";
import * as d3 from "d3";

import ExpandableTable from "../../components/ExpandableTable/ExpandableTable";
import ExpandableTableHeader from "../../components/ExpandableTable/ExpandableTableHeader";
import ExpandableTableBody from "../../components/ExpandableTable/ExpandableTableBody";
import Pagination from "../../components/Pagination/Pagination";

import "./EvaluationDaily.css";

const EvaluationDailyContainer = (props) => {
    const [dailyData, setDailyData] = useState([]);
    const [slicedDailyData, setSlicedDailyData] = useState([]);
    const [expandedAreasActive, setExpandedAreasActive] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const displayNr = 7;

    const columnNames = {
        "date": "Date",
        "numberOfRises": "Rises",
        "numberOfFalls": "Falls"
    };
    const columnStyles = {
        width: ["90%", "5%", "5%"],
        minWidth: ["40px", "40px", "40px"],
        align: ["left", "right", "right"],
        fontStyle: ["number", "number", "number"],
        icon: ["none", "greenUp", "redDown"]
    };

    const formatDate = (dateString) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 8);

        const inputDate = new Date(dateString);

        if (inputDate >= sevenDaysAgo && inputDate <= yesterday) {
            const diffInDays = Math.floor((today - inputDate) / (1000 * 60 * 60 * 24));
            if (diffInDays === 1) {
                return "Today";
            } else if (diffInDays === 2) {
                return "Yesterday"
            } else {
                return `${diffInDays-1} days ago`;
            }
        }

        // For dates older than 7 days, return a formatted string like "November 2nd"
        const options = { month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDate);

        // Adding suffix to the day of the month (e.g., 1st, 2nd, 3rd, etc.)
        const dayOfMonth = inputDate.getDate();
        let suffix = 'th';
        if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
            suffix = 'st';
        } else if (dayOfMonth === 2 || dayOfMonth === 22) {
            suffix = 'nd';
        } else if (dayOfMonth === 3 || dayOfMonth === 23) {
            suffix = 'rd';
        }
        return `${formattedDate}${suffix}`;
    };

    useEffect(() => {
        if (!props.dataLoading) {
            let data = props.data.sort((a,b) => a["date"] < b["date"] ? 1 : -1).map((d, idx) => {
                return {
                    "data": d["data"],
                    "date": formatDate(d["date"]),
                    "numberOfRises": d["data"]["rises"].length,
                    "numberOfFalls": d["data"]["falls"].length
                }
            });
            setDailyData(data);
            setExpandedAreasActive(true);

            let dataLength = data.length;
            let numPages = Math.ceil(dataLength / displayNr);
            setTotalPages(numPages);

            let slicedData = data.slice(0, displayNr);
            setSlicedDailyData(slicedData);
        }
    }, [props]);

    useEffect(() => {
        let slicedData = dailyData.slice((pageNumber - 1) * displayNr, pageNumber * displayNr);
        setSlicedDailyData(slicedData);
    }, [dailyData, pageNumber, displayNr]);

  useEffect( () => {
    if (!expandedAreasActive) {
      d3.selectAll(".table-row-container.active")
          .classed("table-row-container active", false)
          .classed("table-row-container inactive", true);

      d3.selectAll(".table-row-container.active-initial")
          .classed("table-row-container active-initial", false)
          .classed("table-row-container inactive", true);
    }
  }, [expandedAreasActive]);

  const handleActivationExpandedAreas = (bool) => {
    setExpandedAreasActive(bool);
  }

  const paginationHandler = (page) => {
    setExpandedAreasActive(false);
    setPageNumber(page);
  };

    const table = (
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
          <div className={"daily-container noSelect"}>
              <ExpandableTableHeader
                  headers={["Date"]}
                  headerInfo={columnStyles}
                  onClick={[]}
                  excludedColumns={[]}
              />
              <ExpandableTableBody
                  data={slicedDailyData}
                  rowItemNames={columnNames}
                  rowItemInfo={columnStyles}
                  firstRowActive={true}
                  expandedAreaType={"evaluation"}
                  hiddenColumns={['data']}
                  expandedAreasActive={expandedAreasActive}
                  handleActivationExpandedAreas={handleActivationExpandedAreas}
                  clickedHeader={[]}
                  excludedColumns={[]}
              />
          </div>

          <Pagination
              totalPages={totalPages}
              currentPage={pageNumber}
              handlePaginationClick={paginationHandler}
          />
      </div>
    );

    return table;
};

export default EvaluationDailyContainer;

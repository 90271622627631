import React, {useEffect, useState} from "react";
import * as d3 from "d3";

import ContentContainer from "../../components/ContentContainer/ContentContainer";
import EvaluationDailyContainer from "./EvaluationDailyContainer";
import EvaluationLeaderboardContainer from "./EvaluationLeaderboardContainer";
import "./Evaluation.css";


const Evaluation = (props) => {
	const [dataDump, setDataDump] = useState({});
	const [dataLoading, setDataLoading] = useState(true);

    const [evaluationActiveTab, setEvaluationActiveTab] = useState('leaderboard');

	const tabs = ['leaderboard', 'dailydata']
    const tabNames = {
        leaderboard: 'leaderboard',
        dailydata: 'daily data',
    }


	useEffect(() => {
        const fetchDataDev = async() => {
            await d3.json("/fetch-data").then((response) => {
                setDataDump(response);
            });
        }

        const fetchDataProd = () => {
            setDataDump(window.data);
        }

        if (window.location.hostname === "localhost") {
            fetchDataDev();
        } else {
            fetchDataProd();
        }
    }, []);

	useEffect(() => {
		if (Object.keys(dataDump).length > 0) {
			setDataLoading(false);
		}
	}, [dataDump]);

	const leaderboard = (active) => {
		return <div className={"page-part-white"} style={{display: active ? 'block' : 'none' }}>
			<div style={{maxWidth: "500px", margin: "0 auto", textAlign: "justify"}}>

				<p className={"subtitle-text"} style={{marginTop: "0px"}}>
					Find out which predictor is best
				</p>
			</div>

			<ContentContainer containerStyle={"content-container-fixed-width"}>
				<EvaluationLeaderboardContainer
					data={dataDump['summary_stats']}
					dataLoading={dataLoading}
				/>
			</ContentContainer>
			<div className={"explainer-text"}>
				<div style={{maxWidth: "450px", margin: "0 auto", textAlign: "center"}}>
					<p>
						A perfect score is 100, and the worst possible score is 0. It's called the F1-score
						(multiplied by 100), a commonly used metric in statistics to assess prediction models.
					</p>
				</div>
			</div>
		</div>
	}

	const dailydata = (active) => {
		return <div className={"page-part-white"} style={{display: active ? 'block' : 'none' }}>
			<div style={{maxWidth: "500px", margin: "0 auto", textAlign: "justify"}}>
				<p className={"subtitle-text"} style={{marginTop: "0px"}}>
					How are the predictors doing day-to-day
				</p>
			</div>
			<ContentContainer containerStyle={"content-container-flex-when-small"}>
				<EvaluationDailyContainer
					data={dataDump['daily_data']}
					dataLoading={dataLoading}
				/>
			</ContentContainer>
		</div>
	}

	const loadingDiv = (
        <h1 className={"loader"}
          	style={{margin: "0 auto", width: "fit-content", fontFamily: "'Sahar', sans-serif",
            		letterSpacing: "1.2px", textTransform: "uppercase", paddingTop: "50px"}}
        >loading...</h1>
    );


	return (
		<div>
			<div className={"page-part-grey"}>
				<div className={"main-text"}>
					<div style={{maxWidth: "500px", margin: "0 auto", textAlign: "justify"}}>
						<p>
							<span style={{fontSize: "16px", fontWeight: "900"}}>Welcome to What The FPL!</span><br/>
							<b>Price changes</b> in FPL can be annoying — they're unpredictable and sometimes can mess
							up
							your transfer plans!
							<br/>
							Luckily, there are many great websites who try to predict these price changes for us.
							However,
							not all of them are of the same quality. The purpose of this website is to find out
							<b> which predictors to trust</b> when making your transfer decisions.
						</p>
					</div>
				</div>
			</div>
			<div className="evaluation-tabs">
				{tabs.map((tab) => (
					<button
						key={tab}
						className={tab === evaluationActiveTab ? 'evaluation-tab active' : 'evaluation-tab inactive'}
						onClick={() => setEvaluationActiveTab(tab)}
					>
						{tabNames[tab]}
					</button>
				))}
			</div>
			{dataLoading && loadingDiv}
			{!dataLoading && leaderboard(evaluationActiveTab === "leaderboard")}
			{!dataLoading && dailydata(evaluationActiveTab === "dailydata")}
		</div>
	);
};

export default Evaluation;

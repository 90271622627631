import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "./Navbar.css";

import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function Navbar() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropDownIcon = (<FontAwesomeIcon icon={faBars} style={{color: "black", fontSize: 18}}/>)


    const DropDown = () => (
        <div className="dropdown"
            onMouseLeave={() => setDropdownOpen(false)}>
            <button className="dropbtn"
                onMouseEnter={() => setDropdownOpen(true)}

            >
                {dropDownIcon}
            </button>
            {isDropdownOpen && (
                <div className="dropdown-content" onClick={() => setDropdownOpen(false)}>
                    <Link to="/">Home</Link>
                    <Link to="/faq">FAQ</Link>
                </div>
            )}
        </div>
    );

    return (
        <header className="Header" style={{display: "flex", justifyContent: "center"}}>
            <p className="Logo" alt='logo'>
                <Link to="/">
                    <img src="/images/WhatTheFPLlogo.png" alt="What The FPL" width="90" height="auto"/>
                </Link>
            </p>

            <DropDown/>
        </header>
    );
}

export default Navbar;
import React from "react";

const FAQ = (props) => {
	return (
		<div>
			<div className={"page-part-grey"}>
				<div className={"main-text"}>
					<div style={{maxWidth: "550px", margin: "0 auto", textAlign: "justify"}}>
						<p>
							<span style={{fontSize: "16px", fontWeight: "900"}}>FAQ</span><br/>
							<br/>
							<b>Why are you doing this?</b>
							<br/>
							There was a lot of dialog online about FPL price change predictors, and which was best.
							But none of it was backed up by any facts. I created this website to track the price
							predictors and find out once and for all which is best.

							<br/><br/>
							<b>What is this "score" you are using?</b>
							<br/>
							The score is the F1-score, multiplied by 100 so it's easier to read. The F1-score is a very
							common metric used in statistics to evaluate and compare (categorical) predictions. It takes
							into account two things:<br/>
							<ul>
								<li>"accuracy" — i.e. what percentage of your predicted rises/falls actually happen</li>
								<li>"recall" — i.e. what percentage of actual rises/falls did you 'catch'</li>
							</ul>
							The F1-score is the "harmonic" mean between accuracy and recall.

							<br/><br/>
							<b>Do you take into account false positives?</b>
							<br/>
							Yes! The "accuracy" part of the score does just that. If you have a lot of false positives,
							you will have a low "accuracy".

							<br/><br/>
							<b>Cool logo!</b>
							<br/>
							Thanks, but I can't take credit for it. It's a replica of an old design by an Asian clothing
							brand that no longer exists, and the logo is no longer used. If it any point I'm contacted
							by them I will probably remove it — but for now I thought it looked pretty cool!<br/>

						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;

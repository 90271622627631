import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle} from '@fortawesome/free-regular-svg-icons'
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons'

import "./ExpandedAreaEvaluation.css";
import Spacer from "../Spacer/Spacer";

const ExpandedAreaEvaluation = (props) => {
    const [riseData, setRiseData] = useState([]);
    const [fallData, setFallData] = useState([]);
    const [riseScoreData, setRiseScoreData] = useState([]);
    const [fallScoreData, setFallScoreData] = useState([]);
    const [riseErrorData, setRiseErrorData] = useState({});
    const [fallErrorData, setFallErrorData] = useState({});

    useEffect(() => {
        if (props.data) {
          let today = props.data.data;
          setRiseData(today['rises']);
          setFallData(today['falls']);
          setRiseScoreData(today['rise_scores']);
          setFallScoreData(today['fall_scores']);
          setRiseErrorData(today['wrong_rises']);
          setFallErrorData(today['wrong_falls']);
        }
      }, [props.data]);

    const numberOfRises = riseData.length;
    const numberOfFalls = fallData.length;

    const predictors = ["fpl", "ffx", "ffh", "livefpl"];
    const predictorNames = {
      fpl: "FPL",
      ffx: "FFX",
      ffh: "FFH",
      livefpl: "LIV"
    }
    const predictorNamesFull = {
        fpl: "fplstatistics",
        ffx: "fantasyfootballfix",
        ffh: "fantasyfootballhub",
        livefpl: "livefpl"
    }
    const greenUp = (<FontAwesomeIcon icon={faAnglesUp} style={{color: "green", fontSize: 18}}/>)
    const redDown = (<FontAwesomeIcon icon={faAnglesDown} style={{color: "red", fontSize: 18}}/>)

    const checkMark = (<FontAwesomeIcon icon={faCheckCircle} style={{color: "green", fontSize: 15}}/>)
    const redCross = (<FontAwesomeIcon icon={faCircleXmark} style={{color: "red", fontSize: 15}}/>)
    const noData = (<FontAwesomeIcon icon={faQuestionCircle} style={{color: "grey", fontSize: 15}}
                                           data-tooltip-id="no-data-tooltip"/>)

    const predictorNamesIcon = Object.entries(predictorNamesFull).reduce((acc, [key, value]) => {
        acc[key] = (<FontAwesomeIcon icon={faQuestionCircle}
                                     style={{color: "grey", fontSize: 11, marginRight: "2px"}}
                                     data-tooltip-id="predictor-tooltip"
                                     data-tooltip-content={value} />);
        return acc;
    }, {});

    const falsePositiveIcon = (<FontAwesomeIcon icon={faQuestionCircle}
                                                         style={{color: "grey", fontSize: 12, marginLeft: "2px"}}
                                                         data-tooltip-id="false-positive-tooltip"
                                                         data-tooltip-html="When a predictor predicts a rise/fall,<br/> but it doesn't actually happen."/>)

    const toolTips = [
        <Tooltip id="no-data-tooltip" content="Unfortunately we have no data for this datapoint." />,
        <Tooltip id="predictor-tooltip" className="my-tooltip" style={{textTransform: "uppercase", fontFamily: "DM Mono"}}/>,
        <Tooltip id="false-positive-tooltip" className="my-tooltip"/>
    ]

    const noPriceChanges = (type) => (
        <div className="expanded-area-table-row" style={{height: "100%"}}>
            <div className="expanded-area-table-row-announcement table-center-align"
                 style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: "75px"}}>
                No price {type} today!
            </div>
        </div>
    )

    const riseIcon = (value) => {
        if (value === null) {
            return noData
        }
        if (value >= 100) {
            return checkMark
        } else {
            return redCross
        }};

    const fallIcon = (value) => {
        if (value === null) {
            return noData
        }
        if (value <= -100) {
            return checkMark
        } else {
            return redCross
        }};

    const halfExpandedArea = (changeType, data, scoreData, errorData, total) => {
        return <div className="expanded-area-half noSelect">
                <div className="expanded-area-half-container noSelect">
                    <div className="expanded-area-top-wrapper noSelect">
                        <div className="expanded-area-table-row noSelect">
                            <div className="expanded-area-table-title evaluation-table-left-align">
                                {changeType === "rises" && greenUp}{changeType === "falls" && redDown} {changeType}
                            </div>
                        </div>
                        <div className="expanded-area-table-row">
                            <div className="expanded-area-table-sub-title evaluation-table-left-align">
                                Actual
                            </div>
                                {predictors.map((row, row_idx) => {
                                    return <div key={row_idx}
                                                className="expanded-area-table-column-title evaluation-table-right-align">
                                        {predictorNamesIcon[row]}{predictorNames[row]}
                                    </div>
                                })}
                        </div>

                        <div>
                            {total === 0 && noPriceChanges(changeType)}
                            {data.map((row, row_idx) => {
                                return (
                                    <div key={row_idx} className="expanded-area-table-row">
                                        <div
                                            className="expanded-area-table-row-title evaluation-table-left-align">{row.player_name}</div>
                                        <div className="expanded-area-table-row-item evaluation-table-right-align">
                                            {changeType === "rises" && riseIcon(row.fpl)}
                                            {changeType === "falls" && fallIcon(row.fpl)}
                                        </div>
                                        <div className="expanded-area-table-row-item evaluation-table-right-align">
                                            {changeType === "rises" && riseIcon(row.ffx)}
                                            {changeType === "falls" && fallIcon(row.ffx)}
                                        </div>
                                        <div className="expanded-area-table-row-item evaluation-table-right-align">
                                            {changeType === "rises" && riseIcon(row.ffh)}
                                            {changeType === "falls" && fallIcon(row.ffh)}
                                        </div>
                                        <div className="expanded-area-table-row-item evaluation-table-right-align">
                                            {changeType === "rises" && riseIcon(row.livefpl)}
                                            {changeType === "falls" && fallIcon(row.livefpl)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {
                            total === 0 ? <div/> :
                                <div style={{
                                    width: "auto",
                                    borderTop: "1px solid #F1F1F1"
                                }}>
                                    <div className="expanded-area-table-row">
                                        <div className="expanded-area-table-row-title evaluation-table-left-align">
                                            <FontAwesomeIcon icon={faCheckCircle} style={{opacity: 0}}/>
                                        </div>
                                        {predictors.map((predictor, predictor_idx) => {
                                            return <div key={"col-idx-" + predictor_idx}
                                                        className="expanded-area-table-row-item evaluation-table-right-align">
                                                <span style={{fontSize: "14px"}}>{scoreData[predictor] === -100 ? "?": scoreData[predictor]}</span>
                                                <span style={{fontSize: "10px"}}>/{total}</span>
                                            </div>
                                        })}
                                    </div>
                                </div>
                        }
                        <Spacer style={{ marginBottom: "12px" }} />
                    </div>
                    <div className="expanded-area-bottom-wrapper">
                        <div style={{
                            width: "auto", bottom: 0
                        }}>
                            <div className="expanded-area-table-row">
                                <div className="expanded-area-table-sub-title evaluation-table-left-align">False Positives{falsePositiveIcon}</div>
                                {(Object.keys(errorData).length>0) && predictors.map((predictor, predictor_idx) => {
                                    return <div key={"col-idx-" + predictor_idx}
                                                className="expanded-area-table-row-item evaluation-table-right-align">
                                        {errorData[predictor] === "unknown" ? "?" : errorData[predictor].length}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {toolTips.map((row, row_idx) => {
                    return row
                })}
            </div>
    }

    const expandedAreaEvaluation = (
        <div>
            <div className={"expanded-area-container noSelect"}>
                {halfExpandedArea("rises", riseData, riseScoreData, riseErrorData, numberOfRises)}
                <hr className={"expanded-area-divider"}/>
                {halfExpandedArea("falls", fallData, fallScoreData, fallErrorData, numberOfFalls)}
            </div>
            {/*<Spacer style={{ marginBottom: "10px" }} />*/}
        </div>

    );

    return expandedAreaEvaluation;
}

export default ExpandedAreaEvaluation;
import React, { useState, useEffect } from "react";
import * as d3 from "d3";

import ExpandableTable from "../../components/ExpandableTable/ExpandableTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesDown, faAnglesUp, faEquals, faMedal} from "@fortawesome/free-solid-svg-icons";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";

import Spacer from "../../components/Spacer/Spacer";

import "./EvaluationLeaderboard.css";
import {Tooltip} from "react-tooltip";


const EvaluationLeaderboardContainer = (props) => {
    const [leaderboardData, setLeaderboardData] = useState({});
    const [activeData, setActiveData] = useState({});
    const [activeTab, setActiveTab] = useState('monthly');

    const tabs = ['alltime', 'monthly', 'weekly']
    const tabNames = {
        alltime: 'season',
        monthly: 'monthly',
        weekly: 'weekly'
    }

    const greenUp = (<FontAwesomeIcon icon={faAnglesUp} style={{color: "green", fontSize: 18}}/>)
    const redDown = (<FontAwesomeIcon icon={faAnglesDown} style={{color: "red", fontSize: 18}}/>)
    const rank1 = (<FontAwesomeIcon icon={faMedal} style={{color: "#fabe2c", fontSize: 12, marginRight: "2px"}}/>)
    const rank2 = (<FontAwesomeIcon icon={faMedal} style={{color: "#9e9e9e", fontSize: 12, marginRight: "2px"}}/>)
    const rank3 = (<FontAwesomeIcon icon={faMedal} style={{color: "#ce7430", fontSize: 12, marginRight: "2px"}}/>)
    const rankIcons = {
        1: rank1,
        2: rank2,
        3: rank3,
        4: null
    }

    const improvedIcon = (<FontAwesomeIcon icon={faAnglesUp} style={{color: "green", fontSize: 11, marginRight: "1px", marginLeft: "1px"}}/>)
    const worsenedIcon = (<FontAwesomeIcon icon={faAnglesDown} style={{color: "red", fontSize: 11, marginRight: "1px", marginLeft: "1px"}}/>)
    const equalIcon = (<FontAwesomeIcon icon={faEquals} style={{color: "gray", fontSize: 11, marginRight: "1px", marginLeft: "1px"}}/>)

    const changeIcon = (change) => {
        let roundedChange = (change*100).toFixed(1)
        if (roundedChange > 0) {
            return improvedIcon
        } else if (roundedChange < 0) {
            return worsenedIcon
        } else {
            return equalIcon
        }
    }

    const scoreIcon = (<FontAwesomeIcon icon={faQuestionCircle}
                                 style={{color: "grey", fontSize: 11, marginRight: "2px"}}
                                 data-tooltip-id="score-tooltip"
                                 data-tooltip-html="<b>F1-score</b> (x100) <br/> 100 is a perfect score."/>)

    const toolTips = [
        <Tooltip id="score-tooltip" className="my-tooltip"/>
    ]

    const predictorNamesFull = {
        fpl: "FPLStats",
        ffx: "FFFix",
        ffh: "FFHub",
        livefpl: "LiveFPL"
    }

    const predictorColor = {
        fpl: '#001e5d',
        ffx: '#4dfb0e',
        ffh: '#009de5',
        livefpl: '#1aad7e'
    }

    const getLatestValue = (listdata) => {
        return listdata.sort(
            (a, b) => a[0] > b[0] ? 1 : -1
        )[listdata.length - 1][1]
    }

    const getLatestDiff = (listdata) => {
        if(listdata.length === 1) {
            return 0
        }

        let sortList = listdata.sort(
            (a, b) => a[0] > b[0] ? 1 : -1
        )

        return sortList[sortList.length - 1][1] - sortList[sortList.length - 2][1]
    }

    useEffect(() => {
        if (!props.dataLoading) {
            let data = props.data;
            setLeaderboardData(data);
            setActiveData(data[activeTab]);
        }
    }, [props]);


    useEffect(() => {
        if (Object.keys(leaderboardData).length > 0) {
            setActiveData(leaderboardData[activeTab]);
        }
    }, [activeTab]);

    const tabsDivs = () => {
        return <div className="leaderboard-half noSelect" style={{"width": "100%"}}>
                <div className="leaderboard-tabs">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            className={tab === activeTab ? 'leaderboard-tab active' : 'leaderboard-tab inactive'}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tabNames[tab]}
                        </button>
                    ))}
                </div>
        </div>
    }

    const half = (changeType, data) => {
        return <div className="leaderboard-half noSelect">
            <div className="leaderboard-half-container noSelect">
                <div className="leaderboard-table-row noSelect">
                    <div className="leaderboard-table-title" style={{width: "55%", minWidth: "90px"}}>
                        {changeType === "rises" && greenUp}{changeType === "falls" && redDown} {changeType}
                    </div>
                    <div className="leaderboard-table-column-name" style={{width: "25%"}}>
                        {scoreIcon}Score
                    </div>
                    <div className="leaderboard-table-column-name" style={{width: "20%", minWidth: "30px"}}>
                        ±
                    </div>
                </div>
                {data && (Object.keys(data).length > 0) &&
                    Object.entries(data).sort(
                        (a, b) => getLatestValue(a[1]["f1"]) < getLatestValue(b[1]["f1"]) ? 1 : -1
                    ).map(([key, value], idx) => {
                        return <div key={idx} className="leaderboard-table-row">
                            <div className="leaderboard-table-row-square" style={{width: "6%"}}>
                                <div key={"col-idx-" + idx} className="leaderboard-table-row-item-left">
                                    {rankIcons[idx + 1]}
                                </div>
                            </div>
                            <div className="leaderboard-table-row-square" style={{width: "49%"}}>
                                <div key={"col-idx-" + idx} className="leaderboard-table-row-item-left">
                                    {predictorNamesFull[key]}
                                </div>
                            </div>
                            <div className="leaderboard-table-row-square" style={{width: "25%"}}>
                                <div key={"col-idx-" + idx}
                                     className="leaderboard-table-row-item"
                                     style={{"background": `rgba(130, 165, 185, ${getLatestValue(value['f1']) ** 2})`}}>
                                    {(getLatestValue(value['f1']) * 100).toFixed(1)}
                                </div>
                            </div>
                            <div className="leaderboard-table-row-square" style={{width: "20%"}}>
                                <div key={"col-idx-" + idx} className="leaderboard-table-row-item-light">
                                    {changeIcon(getLatestDiff(value['f1']))}{Math.abs(getLatestDiff(value['f1']) * 100).toFixed(1)}
                                </div>
                            </div>
                        </div>
                    })}

            </div>
            {toolTips.map((row, row_idx) => {
                return row
            })}
        </div>
    }

    const table = (
        <div>
            <ExpandableTable>
                <div className={"leaderboard-container noSelect"}>
                    {tabsDivs()}
                    <div className={"leaderboard-sub-container noSelect"}>
                        {half('rises', activeData['rises'])}
                        <hr className={"leaderboard-divider"}/>
                        {half('falls', activeData['falls'])}
                    </div>
                </div>
            </ExpandableTable>
        </div>
    );

    return table;
};

export default EvaluationLeaderboardContainer;

import React, { useState } from "react";

import ExpandedAreaPrediction from "./ExpandedAreaPrediction.js"
import ExpandedAreaEvaluation from "./ExpandedAreaEvaluation.js"

const ExpandedArea = (props) => {
  let areaType = props.areaType;
  const [loading, setLoading] = useState(false);

  const isLoaded = () => {
    return !loading;
  };

  const expandedArea = areaType === "prediction" ? (
        <ExpandedAreaPrediction
            data={props.data}
        />
    ) : areaType === "evaluation" ? (
        <ExpandedAreaEvaluation
            data={props.data}
        />
    ) : [];

  if (isLoaded()) {
    return (
      <div>
        {expandedArea}
      </div>
    );
  }
  return <div style={{ minHeight: "600px" }}></div>;
};

export default ExpandedArea;
